import { I18n } from 'aws-amplify'



export const LocationStrings = label => ({
  addLocation: `${I18n.get('Add')} ${label.text.toLocaleLowerCase()}`,
  addLocationModal: `${I18n.get('Add new')} ${label.text.toLocaleLowerCase()}`,
  addLocationModalSubheading: `${I18n.get(
    'Enter a unique name and the relevant details for the'
  )} ${label.text.toLocaleLowerCase()}`,
  locationName: `${label.text} ${I18n.get('name')}`,
  locationId: `${label.text} ${I18n.get('ID')}`,
  createLocationButton: `${I18n.get(
    'Create'
  )} ${label.text.toLocaleLowerCase()}`,
  updateLocationModal: `${I18n.get(
    'Update'
  )} ${label.text.toLocaleLowerCase()}`,
  updateLocationModalSubheading: `${I18n.get(
    'Enter new details for'
  )} ${label.text.toLocaleLowerCase()} ${I18n.get('with ID')}`,
  editLocationDetails: `${I18n.get('Edit')} ${label.text.toLocaleLowerCase()} ${I18n.get('details')}`,
  noLocations: `${I18n.get('No')} ${label.textPlural.toLocaleLowerCase()} ${I18n.get('found')}`,
  locationsHeading: `${label.textPlural}`,
  locationsSubheading: `${I18n.get('The following')} ${label.textPlural} ${I18n.get('are subzones of')}`,
  locationDetailsSubheading: `${I18n.get('These are the details associated with the')} ${label.text.toLocaleLowerCase()}`
})


const Strings = () => ({
  dataApiTab: I18n.get('Data API'),
  userManagementTab: I18n.get('User Management'),
  userRolesTab: I18n.get('User Roles'),
  systemThresholdsTab: I18n.get('System Thresholds'),
  crmTab: I18n.get('Control Range Management'),
  noUsersFound: I18n.get('No users were found'),
  addUserButton: I18n.get('Add User'),
  tableUserColumn: I18n.get('User'),
  tableRoleColumn: I18n.get('Role'),
  tableUsernameColumn: I18n.get('Username'),
  tableRegistrationColumn: I18n.get('Registration Date'),
  tableStatusColumn: I18n.get('Status'),
  tableLastSeenColumn: I18n.get('Last Seen'),
  tableActionsColumn: I18n.get('Actions'),
  addRoleButton: I18n.get('Add Role'),
  roleHeader: I18n.get('Role'),
  roleOrganizationsHeader: I18n.get('Organizations'),
  cameraAccessHeader: I18n.get('Camera Access'),
  sensorControlHeader: I18n.get('Sensor Control'),
  thresholdAdminHeader: I18n.get('Threshold Admin'),
  deleteButton: I18n.get('Delete'),
  editButton: I18n.get('Edit'),
  viewButton: I18n.get('View'),
  editButtonSentence: I18n.get('Edit'),
  viewButtonSentence: I18n.get('View'),
  removeButton: I18n.get('Remove User'),
  loadingOrganizations: I18n.get('Loading organizations'),
  loadingUsers: I18n.get('Loading users'),
  loadingRoles: I18n.get('Loading roles'),
  loadingThresholds: I18n.get('Loading thresholds'),

  // Search By FieldName
  searchByUserName: I18n.get('Search by user name'),
  searchByRoleName: I18n.get('Search by role name'),
  searchDevices: I18n.get('Search Devices'),
  searchByName: I18n.get('Search by name'),

  // Vendors
  vendorsTab: I18n.get('Vendors'),
  vendorsTabSubtitle: I18n.get('View and create device vendors'),
  addVendorModal: I18n.get('Add vendor'),
  addVendorModalSubheading: I18n.get('Create a new device vendor'),
  saveVendorDetails: I18n.get('Save vendor'),
  vendorFormName: I18n.get('Vendor name'),
  updateVendorModal: I18n.get('Edit vendor'),
  updateVendorModalSubheading: I18n.get('Update vendor details'),
  loadingVendors: I18n.get('Loading vendors'),
  vendorUpdated: I18n.get('Vendor updated'),
  vendorCreated: I18n.get('Vendor created'),

  // Types
  typesTab: I18n.get('Types'),
  typesTabSubtitle: I18n.get('View and create device types'),
  addType: I18n.get('Add type'),
  addTypeSubheading: I18n.get('Create a new device type'),
  saveTypeDetails: I18n.get('Save type'),
  typeFormVendorId: I18n.get('Vendor'),
  typeFormName: I18n.get('Name'),
  typeFormModel: I18n.get('Model'),
  typeFormModelVersion: I18n.get('Model version'),
  typeFormFirmwareVersion: I18n.get('Firmware version'),
  typeFormCommsProtocol: I18n.get('Communications protocol (separate entries by comma)'),
  updateTypeHeading: I18n.get('Edit type'),
  updateTypeSubheading: I18n.get('Update type details'),
  typeInputs: I18n.get('Type inputs'),
  addNew: I18n.get('Add new'),
  lowerThresholdIn: I18n.get('Lower threshold in'),
  upperThresholdIn: I18n.get('Upper threshold in'),
  remove: I18n.get('Remove'),
  accept: I18n.get('Accept'),
  selectMeasurementType: I18n.get('Select a measurement type'),
  loadingTypes: I18n.get('Loading types'),
  typeUpdated: I18n.get('Type updated'),
  typeCreated: I18n.get('Type created'),

  // Measurements
  measurementsTab: I18n.get('Measurements'),
  measurementsTabSubtitle: I18n.get('View and create measurement types with custom threshold ranges'),
  addMeasurement: I18n.get('Add measurement'),
  addMeasurementSubheading: I18n.get('Create a new device measurement'),
  saveMeasurementDetails: I18n.get('Save measurement'),
  measurementFormId: I18n.get('ID'),
  measurementFormType: I18n.get('Type'),
  measurementFormSelectType: I18n.get('Select a type'),
  measurementFormDescription: I18n.get('Description'),
  measurementFormShortName: I18n.get('Short name'),
  measurementFormUnit: I18n.get('Unit of measurement'),
  measurementFormThreshold: I18n.get('Specify threshold range'),
  measurementFormUpperThreshold: I18n.get('Upper threshold'),
  measurementFormLowerThreshold: I18n.get('Lower threshold'),
  updateMeasurementHeading: I18n.get('Edit measurement'),
  updateMeasurementSubheading: I18n.get('Update measurement details'),
  measurementUpdated: I18n.get('Measurement updated'),
  measurementCreated: I18n.get('Measurement created'),
  filterByType: I18n.get('Filter by type'),

  // Device Types
  flashScriptsTab: I18n.get('Flash Scripts'),
  flashScriptsSubheading: I18n.get('View and create flash scripts'),
  noFlashScripts: I18n.get('No available flash scripts'),
  addFlashScript: I18n.get('Add flash script'),
  editFlashScript: I18n.get('Edit flash script'),
  addFlashScriptSub: I18n.get('Create new flash script'),
  editFlashScriptSub: I18n.get('Update flash script details'),
  flashScriptDeviceType: I18n.get('Device Type'),
  flashScriptVersion: I18n.get('Version'),
  flashScriptDescription: I18n.get('Description'),
  flashScriptUploadFlashDebugFile: I18n.get('Flash script debug file'),
  flashScriptUploadFlashReleaseFile: I18n.get('Flash script release file'),
  flashScriptUploadFlashReleaseDebugFile: I18n.get('Flash script release debug file'),
  flashScriptCreatedSuccessfully: I18n.get('Flash script created successfully.'),
  flashScriptUpdatedSuccessfully: I18n.get('Flash script updated successfully.'),
  deviceTypesTab: I18n.get('Device Types'),
  deviceTypesTabSubheading: I18n.get('View and create device types'),
  deviceFormId: I18n.get('ID'),
  deviceFormName: I18n.get('Name'),
  deviceFormType: I18n.get('Type'),
  deviceFormMeasurements: I18n.get('Measurements'),
  deviceFormHasFirmware: I18n.get('Device has firmware'),
  deviceFormSupportsGreengrass: I18n.get('Device supports Greengrass'),
  addDeviceType: I18n.get('Add device type'),
  editDeviceType: I18n.get('Edit device type'),
  noDeviceTypes: I18n.get('No available device types'),
  hardwareVersion: I18n.get('Hardware Version'),
  firmwareVersions: I18n.get('Firmware Versions'),
  flashScriptVersionTable: I18n.get('Flash Script'),
  selectVersions: I18n.get('Select versions'),
  selectVersion: I18n.get('Select version'),
  enterVersion: I18n.get('Enter version'),
  addHardwareVersion: I18n.get('Add hardware version'),
  noFirmwareVersions: I18n.get('No available firmware versions'),
  addDeviceTypeSub: I18n.get('Create new device type'),
  editDeviceTypeSub: I18n.get('Update device type details'),
  deviceTypeCreatedSuccessfully: I18n.get('Device type created successfully.'),
  deviceTypeUpdatedSuccessfully: I18n.get('Device type updated successfully.'),

  // Users
  addUserHeading: I18n.get('Add New User'),
  addUserSubheading: I18n.get('Create a new user and add them to the default zones.'),
  editUserHeading: I18n.get('Edit User Profile'),
  editUserSubheading: I18n.get('Edit user settings.'),
  viewUserHeading: I18n.get('View User Profile'),
  viewUserSubheading: I18n.get('View user settings.'),
  userDetails: I18n.get('User Details'),
  assignRole: I18n.get('Assign Role'),
  userRole: I18n.get('User Role'),
  firstName: I18n.get('First name'),
  lastName: I18n.get('Last name'),
  userName: I18n.get('Username'),
  userFormUserSelectPlaceholder: I18n.get('Please select a role'),
  email: I18n.get('Email'),
  phoneNumber: I18n.get('Phone Number'),
  assignDefaults: I18n.get('Assign Defaults'),
  saveUserBtn: I18n.get('Save User Details'),
  resetFields: I18n.get('Reset Fields'),
  removeUserHeadingPart1: I18n.get('Remove user'),
  removeUserSubheading: I18n.get('You are about to permanently remove a user.'),
  deleteUserButton: I18n.get('Delete user'),
  selectAllRoles: I18n.get('All roles'),
  selectAllOrganizations: I18n.get('All organizations'),


  // Banner messages
  errorMessage: I18n.get('Please complete all fields in the form.'),
  errorInvalidPhoneNumber: I18n.get('Please provide a valid phone number.'),

  // users
  userCreated: I18n.get('New user created.'),
  userCreateError: I18n.get('New user could not be created.'),
  userUpdated: I18n.get('User details updated.'),
  userUpdateError: I18n.get('User could not updated.'),
  userDeleted: I18n.get('User deleted.'),
  userDeleteError: I18n.get('User could not be deleted.'),
  roleCreated: I18n.get('New role created.'),
  roleCreatedError: I18n.get('New role could not be created.'),
  roleDeleted: I18n.get('Role deleted.'),
  roleDeletedError: I18n.get('Role could not be deleted.'),
  rolePermissionAdded: I18n.get('Role permission added.'),
  rolePermissionAddError: I18n.get('Role permission not updated.'),
  rolePermissionRemoved: I18n.get('Role permission removed.'),
  rolePermissionRemoveError: I18n.get('Role permission could not be removed.'),
  roleFetchedError: I18n.get('Role not fetched.'),
  rolesFetchedError: I18n.get('Roles not fetched.'),
  rolePermissionsFetchedError: I18n.get('Role permissions not fetched.'),
  roleUsersFetchedError: I18n.get('Role users not fetched.'),
  userInviteSent: I18n.get('Invitation sent.'),
  userInviteError: I18n.get('Invitation could not be sent.'),

  // Edit Role
  editRoleHeading: I18n.get('Permission Settings'),
  editRoleSubheading: I18n.get('Manage the permissions granted to users with this role'),
  resetRoleState: I18n.get('Reset to previous state'),
  loadingPermissions: I18n.get('Loading permissions'),
  rolePermissionsInfoView: I18n.get('Use the main toggle to enable viewing the'),
  rolePermissionsInfoEdit: I18n.get('Use the main toggle to enable editing the'),
  rolePermissionsInfoPost: I18n.get('for all zones.'),
  noZonesInOrg: I18n.get('No zones found for organization'),
  pageWillRefresh: I18n.get(
    'The page will refresh to get the latest zones per organization.'
  ),

  // Create Role
  addRoleModal: I18n.get('Add new role'),
  addRoleModalSubheading: I18n.get('Enter a unique name for the role, and select a base role to use as a template.'),
  roleName: I18n.get('Role name'),
  baseRole: I18n.get('Base role (inherit permissions from existing role)'),
  cancel: I18n.get('Cancel'),
  close: I18n.get('Close'),
  createRoleButton: I18n.get('Create new role'),
  baseRolePlaceholder: I18n.get('Select a base role'),

  // Generic site/facility/room/zone
  latitudeCoords: I18n.get('Latitude'),
  longitudeCoords: I18n.get('Longitude'),
  timezoneName: I18n.get('Timezone region'),
  statusName: I18n.get('Status'),
  code: I18n.get('Code'),
  ingestionFrequecy: I18n.get('Data Ingestion Frequency'),
  enableAiForecast: I18n.get('Enable AI Forecast'),
  enableMaintenanceMode: I18n.get('Enable Maintenance Mode'),
  coordinates: I18n.get('Coordinates:'),
  coordsUndefined: I18n.get('Coordinates are undefined.'),
  selectTimezone: I18n.get('Select a timezone'),
  selectStatus: I18n.get('Select a status'),
  save: I18n.get('Save'),
  currentLocation: I18n.get('Current location'),
  selectLocation: I18n.get('Select a new location'),
  showProvisioningConfigButton: I18n.get('Show provisioning config'),
  downloadProvisionInstaller: I18n.get('Download Provision Installer'),
  copyToClipboard: I18n.get('Copy to clipboard'),
  generateProvisionConfig: I18n.get('Generate'),
  instructionsFirst: I18n.get('1 - Download the Zip file and transfer it into the intended Greengrass device.'),
  instructionsSecond: I18n.get('2 - On a terminal window in the device, navigate to the downloaded Zip file. Normally this is located at /home/user/downloads'),
  instructionsThird: I18n.get('3 - Unzip the Zip file using an unzip tool for Linux on the command line.'),
  instructionsFourth: I18n.get('4 - Navigate to the Zip file and then execute the setup.sh script.'),
  instructionsFifth: I18n.get('5 - Execute the following command: sudo /bin/bash setup.sh'),
  instructionsNote: I18n.get('NOTE: The setup.sh script will install all required third party tools and set up the Greengrass nucleus as a system service.'),

  // Greengrass Core Device
  greengrassDeviceTitle: I18n.get('Greengrass Core Device'),
  greengrassDeviceStatus: I18n.get('Status'),
  noGreengrassDevice: I18n.get('No core device set up yet.'),
  noGreengrassDeviceDeployments: I18n.get('No deployments for this core device.'),
  greengrassCreateDefaultDeployment: I18n.get('Create default deployment'),
  greengrassDeploymentsModalButton: I18n.get('Show deployments'),
  greengrassDeploymentsModalTitle: I18n.get('Deployments'),
  greengrassDeploymentId: I18n.get('ID'),
  greengrassDeploymentName: I18n.get('Name'),
  greengrassDeploymentStatus: I18n.get('Status'),
  greengrassDeploymentReason: I18n.get('Reason'),
  greengrassDeploymentTimestamp: I18n.get('Date'),
  greengrassRefetchCoreDevice: I18n.get('Refetch core device'),

  // Provisioning Config Modal
  provisioningConfigModalTitle: I18n.get('Provisioning Config'),



  // Subzones - Generic
  zoneDetailsHeading: I18n.get('Details'),
  zoneDetailsLatitude: I18n.get('Latitude'),
  zoneDetailsLongitude: I18n.get('Longitude'),
  zoneDetailsTimezone: I18n.get('Timezone'),

  // Zones admin tabs
  detailsHeading: I18n.get('Details'),
  thresholdsHeading: I18n.get('Thresholds'),
  thresholdsSubheading: I18n.get('The following thresholds are part of'),
  managersHeading: I18n.get('Managers'),
  managersSubheading: I18n.get('The following users can manage the location'),
  fleetManagementHeading: I18n.get('Fleet Management'),
  fleetManagementSubheading: I18n.get('Core devices and sensor management for'),
  fleetManagementOtaSensorHeading: I18n.get('OTA update by sensor'),
  fleetManagementOtaCoreDeviceHeading: I18n.get('OTA update by core device'),
  fleetManagementCoreHeading: I18n.get('Core device update'),
  fleetManagementNvsHeading: I18n.get('Query NVS data'),
  fleetManagementPingerHeading: I18n.get('Query device and sensor status'),
  fleetManagementSimDiagnosticHeading: I18n.get('Request SIM diagnostic data'),
  fleetManagementSimPingeading: I18n.get('Request SIM diagnostic ping'),
  fleetManagementActionSubheading: I18n.get('Select one more devices and/or sensors from the list below, followed by the action.'),
  fleetManagementActionDevicesOnly: I18n.get('Select one more core devices from the list below, followed by the action.'),
  fleetManagementActionSensorsOnly: I18n.get('Select one more sensors from the list below, followed by the action.'),
  controlHeading: I18n.get('Command & Control'),
  controlSubheading: I18n.get('Control pumps and valves with Greengrass in'),
  editSettings: I18n.get('Edit settings'),
  ingestionFrequencyUpdated: I18n.get('Ingestion frequency updated'),
  ingestionValuesHaveNotChanged: I18n.get('The selected values are already applied.'),

  // Fleet management
  fleetManagementResetSelection: I18n.get('Reset selection'),
  fleetManagementDevicesSelected: I18n.get('Devices Selected'),
  fleetManagementSensorsSelected: I18n.get('Sensors Selected'),
  fleetManagementDevicesActive: I18n.get('Devices Active'),
  fleetManagementSensorsActive: I18n.get('Sensors Active'),
  fleetManagementPerformActionBtn: I18n.get('Perform Action'),
  deviceStatusRunning: I18n.get('Running'),
  deviceStatusUnknown: I18n.get('Unknown'),

  // Control
  formAddControlDeviceConfig: I18n.get('Add control device configuration'),
  formEditControlDeviceConfig: I18n.get('Edit control device configuration'),
  formControlDeviceSpecification: I18n.get('Specify control device specification'),
  addControlConfig: I18n.get('Add configuration'),
  editControlConfig: I18n.get('Edit configuration'),
  deleteControlConfig: I18n.get('Delete configuration'),
  viewControlSpec: I18n.get('View specification'),
  noControlDevicesConfig: I18n.get('No control devices configured.'),
  address: I18n.get('Address'),
  slaveAddressControl: I18n.get('Slave address'),
  baudrate: I18n.get('Baudrate'),
  relayConfig: I18n.get('Relay configuration'),
  relayId: I18n.get('Relay ID'),
  registerNumber: I18n.get('Register number'),
  addButton: I18n.get('Add'),
  removeButtonControl: I18n.get('Remove'),
  submit: I18n.get('Save'),
  sensor: I18n.get('Sensor'),
  sensorPlaceholder: I18n.get('Select sensor'),
  relayIdPlaceholder: I18n.get('Select relay by ID'),
  measurement: I18n.get('Measurement'),
  measurementPlaceholder: I18n.get('Select measurement'),
  configConditions: I18n.get('Configure conditions'),
  min: I18n.get('Minimum'),
  minPlaceholder: I18n.get('Enter min value'),
  max: I18n.get('Maximum'),
  maxPlaceholder: I18n.get('Enter max value'),
  addSpecButton: I18n.get('Add specification'),
  removeConfig: I18n.get('Remove configuration from Greengrass core device'),
  confirmRemoveConfig: I18n.get("Are you sure you want to remove this configuration? It will remove any specification attached to it. This action can't be undone."),
  removeRelay: I18n.get('Remove relay from device configuration'),
  configmRemoveRelay: I18n.get("Are you sure you want to remove this relay? It currently has a specification attached to it. This action can't be undone."),

  // Remove Role
  removeRoleHeadingPart1: I18n.get('Remove the'),
  removeRoleHeadingPart2: I18n.get('role'),
  removeRoleSubheading: I18n.get('You are about to remove a role.'),
  fallbackRole: I18n.get('Select a fallback role for affected users.'),
  fallbackRolePlaceholder: I18n.get('Select a fallback role'),
  affectedUsers: I18n.get('Affected users'),
  listAffectedUsers: I18n.get('You can not delete a role that has users. Please assign a new role to each of the following users to continue.'),
  deleteRoleButton: I18n.get('Delete role'),
  noAffectedUsers: I18n.get('No users are currently assigned this role. This role can be safely deleted.'),

  // Devices
  tableNoDevices: I18n.get('No available devices.'),
  tableHeaderDeviceName: I18n.get('Name'),
  tableHeaderDeviceType: I18n.get('Type'),
  tableHeaderDeviceLocation: I18n.get('Location'),
  tableHeaderDeviceHardwareVersion: I18n.get('Hardware Version'),
  tableHeaderDeviceStatus: I18n.get('Status'),
  tableHeaderDeviceTag: I18n.get('Tag'),
  tableHeaderDeviceCreated: I18n.get('Created'),
  tableHeaderDeviceFirmware: I18n.get('Firmware'),
  tableHeaderDeviceSensorType: I18n.get('Sensor'),
  tableHeaderDeviceActions: I18n.get('Actions'),
  tableHeaderDeviceNotInUse: I18n.get('In use'),
  tableHeaderDeviceLastUpdated: I18n.get('Status last updated'),
  placeholderDeviceActionsSelect: I18n.get('Select an action'),
  deviceStatusActive: I18n.get('Active'),
  deviceStatusUnreachable: I18n.get('Unreachable'),
  deviceStatusPending: I18n.get('Pending'),
  tableRowSensorTypeModbus: I18n.get('Modbus'),
  tableRowSensorTypeIoT: I18n.get('IoT'),
  deviceFirmwareRelease: I18n.get('Release'),
  deviceFirmwareReleaseDebug: I18n.get('Release Debug'),
  deviceFirmwareDebug: I18n.get('Debug'),
  deviceFirmwareGreengrass: I18n.get('Greengrass'),
  deviceFirmwareDirectConnect: I18n.get('Direct Connect'),
  deviceEnroll: I18n.get('Enroll device'),
  deviceUpdateType: I18n.get('Update type'),
  deviceRegenerate: I18n.get('Regenerate firmware'),
  firmwareDownload: I18n.get('Download firmware'),
  deviceEdit: I18n.get('Configure Device'),
  deviceEnvirosenseType: I18n.get('Envirosense'),
  deviceWatersenseType: I18n.get('Watersense'),
  deviceIonsenseType: I18n.get('Ionsense'),
  deviceSolarsenseType: I18n.get('Solarsense'),
  deviceCameraType: I18n.get('Camera'),
  devicesHeading: I18n.get('Devices'),
  devicesSubheading: I18n.get('The following devices are part of'),
  enrollDeviceModal: I18n.get('Enroll new device'),
  addDeviceModal: I18n.get('Add new device'),
  addDeviceButton: I18n.get('Add device'),
  enrollDeviceButton: I18n.get('Enroll device'),
  editDeviceModal: I18n.get('Edit device'),
  deviceRefreshNameBtn: I18n.get('Refresh'),
  fetchSupplierDeviceButton: I18n.get('Fetch supplier device'),
  createDeviceButton: I18n.get('Create new device'),
  editDeviceButton: I18n.get('Update device'),
  deviceFormLabelId: I18n.get('ID'),
  deviceFormLabelName: I18n.get('Name'),
  deviceFormLabelDeviceType: I18n.get('Device Type'),
  deviceFormLabelDeviceVersion: I18n.get('Device Version'),
  deviceFormLabelTag: I18n.get('Tag'),
  deviceFormLabelConnectionMethod: I18n.get('Connection Method'),
  deviceFormLabelConnectionType: I18n.get('Connection Type'),
  deviceFormLabelIsIot: I18n.get('Is IoT Sensor?'),
  deviceFormLabelHardwareVersion: I18n.get('Hardware Version Name'),
  deviceFormLabelFirmwareVersions: I18n.get('Firmware Versions'),
  deviceFormTypeSelectPlaceholder: I18n.get('Please select a type'),
  deviceFormVersionSelectPlaceholder: I18n.get('Please select a version'),
  deviceFormSubtitle: I18n.get('Create a new device in'),
  deviceFormEditSubtitle: I18n.get('Edit existing device in'),
  deviceCreatedSuccessfully: I18n.get('Device created successfully.'),
  deviceUpdatedSuccessfully: I18n.get('Device updated successfully.'),
  deviceDeleted: I18n.get('Device deleted successfully.'),
  deviceRemove: I18n.get('Remove'),
  deviceUnenroll: I18n.get('Unenroll'),
  deviceSelectDeviceType: I18n.get('Select a type of device'),
  displayConfigText: I18n.get('Display Configuration'),
  unenroll: I18n.get('Unenroll'),
  removeDevice: I18n.get('Remove Device'),
  confirmRemoveDevice: I18n.get('Are you sure you want to remove this device?'),
  removeUnenroll: I18n.get('Unenroll Device'),
  confirmRemoveUnenroll: I18n.get('Are you sure you want to unenroll this device? You will need to start the enrollment process again.'),
  confirmDeviceType: I18n.get('Are you sure you want to update the type of this device?'),
  requestNvsDump: I18n.get('Request NVS dump'),
  noTimestamp: I18n.get('No timestamp'),
  noTagSet: I18n.get('No tag set'),
  getCoordinates: I18n.get('Get current coordinates'),
  getCoordinatesDescription: I18n.get('These coordinates are based on your current location. Stand as close to the device as possible to get the most accurate location coordinates.'),
  refetchLocation: I18n.get('Refetch location'),
  useCoordinates: I18n.get('Use coordinates'),
  unavailable: I18n.get('Unavailable'),
  locationDenied: I18n.get('SecondSky Data does not have permission to access to your location. Update your browser settings to enable this feature.'),
  locationChanged: I18n.get('The device location has changed. Please update the coordinates to match the new location.'),
  filterByTags: I18n.get('Filter by tags'),

  // Modbus Form
  portAddress: I18n.get('Port Address'),
  slaveAddress: I18n.get('Slave Address'),
  baudRate: I18n.get('Baud Rate'),
  byteSize: I18n.get('Byte Size'),
  stopBits: I18n.get('Stop Bits'),
  timeout: I18n.get('Timeout'),
  measurements: I18n.get('Measurements'),
  multiReadRegisters: I18n.get('Multiple register reads'),
  selectMeasurement: I18n.get('Select Type'),
  readRegisters: I18n.get('Read Registers'),
  readMeasurementType: I18n.get('Read Measurement Type'),
  readMeasurementUnit: I18n.get('Read Measurement Unit'),
  tableActionColumn: I18n.get('Action'),
  tableRegisterColumn: I18n.get('Register'),
  tableRegisterCountColumn: I18n.get('Count'),
  tableTypeColumn: I18n.get('Type'),
  tableUnitColumn: I18n.get('Unit'),

  // Managers
  addManagerModal: I18n.get('Add new manager'),
  addManagerModalSubheading: I18n.get('Select a user and notification channels for a location'),
  editManagerModal: I18n.get('Edit manager'),
  editManagerModalSubheading: I18n.get('Edit notification channels for user in a location'),
  addManagerButton: I18n.get('Add manager'),
  tableHeaderUser: I18n.get('User'),
  tableHeaderUserEmail: I18n.get('Email'),
  tableHeaderSettings: I18n.get('Settings'),
  tableNoManagers: I18n.get('No available managers.'),
  managerErrorOccurred: I18n.get('An error occurred'),
  managerSettingEmail: I18n.get('Email'),
  managerSettingPn: I18n.get('Push Notification'),
  managerSettingSms: I18n.get('SMS'),
  managerSettingWebPush: I18n.get('Web Push'),
  managerSettingWhatsApp: I18n.get('WhatsApp'),
  managerFormUserSelect: I18n.get('User'),
  managerFormUserSelectPlaceholder: I18n.get('Please select a user'),
  createManagerButton: I18n.get('Create new manager'),
  editManagerButton: I18n.get('Update manager'),
  managerCreatedSuccessfully: I18n.get('Manager created successfully.'),
  managerUpdatedSuccessfully: I18n.get('Manager updated successfully.'),
  managerDeletedSuccessfully: I18n.get('Manager deleted successfully.'),
  removeManagerHeading: I18n.get('You are about to remove a manager.'),
  deleteManagerButton: I18n.get('Delete manager'),
  settingsHaveNotChanged: I18n.get('The selected settings are already applied.'),

  // Thresholds
  addThresholdButton: I18n.get('Add threshold'),
  tableHeaderSensor: I18n.get('Device'),
  tableHeaderMeasurement: I18n.get('Measurement'),
  tableHeaderMessage: I18n.get('Message'),
  tableNoThresholds: I18n.get('No available thresholds.'),
  thresholdErrorOccurred: I18n.get('An error occurred'),
  editThresholdHeading: I18n.get('Edit Threshold'),
  addThresholdHeading: I18n.get('Add New Threshold'),
  saveThresholdBtn: I18n.get('Save Threshold'),
  thresholdMeasurementLabel: I18n.get('Measurement'),
  thresholdMessageLabel: I18n.get('Message'),
  thresholdCreated: I18n.get('New threshold created.'),
  thresholdUpdated: I18n.get('Threshold details updated.'),
  thresholdDeleted: I18n.get('Threshold deleted.'),
  removeThresholdTextPart1: I18n.get('Remove the'),
  removeThresholdTextPart2: I18n.get('threshold from'),
  removeThresholdHeading: I18n.get('You are about to remove a threshold.'),
  deleteThresholdButton: I18n.get('Delete threshold'),
  cancelDeleteThresholdButton: I18n.get('Cancel'),
  thresholdIsAboveText: I18n.get('When value is above'),
  thresholdIsBelowText: I18n.get('When value is below'),
  thresholdUpperBoundTitle: I18n.get('Upper Bound Conditions'),
  thresholdLowerBoundTitle: I18n.get('Lower Bound Conditions'),
  thresholdUpperBound: I18n.get('Upper bound'),
  thresholdLowerBound: I18n.get('Lower bound'),
  notificationDefinition: I18n.get('Conditions fall outside of the optimal range. You should keep an eye on this measurement and take action if it does not stabilise.'),
  cautionDefinition: I18n.get('Conditions fall outside of the acceptable range. You should take action to prevent reaching a stage where damage occurs.'),
  warningDefinition: I18n.get('Conditions are adverse. You should take action as soon as possible to prevent irreversible damage.'),

  // Permissions types
  camera: I18n.get('Cameras'),
  zone: I18n.get('Zones'),
  // sensor: I18n.get('Devices'),
  permission: I18n.get('Permissions'),
  data: I18n.get('Data'),
  user: I18n.get('Users'),
  group: I18n.get('Groups'),
  docs: I18n.get('Docs'),
  role: I18n.get('Roles'),
  general: I18n.get('General'),
  threshold: I18n.get('Thresholds'),
  organization: I18n.get('Organization'),
  crm: I18n.get('Control Range Management'),
  operations: I18n.get('Operations'),
  systems: I18n.get('Systems'),
  data_api: I18n.get('Data API'),

  // Enrolment
  enrollmentTitle: I18n.get('Enroll Device'),
  searchPrefixPlaceholer: I18n.get('Search Device'),
  downloadFirmware: I18n.get('Download Firmware'),
  downloadStepTitlePresent: I18n.get('Download Firmware'),
  downloadStepTitlePast: I18n.get('Firmware Downloaded'),
  downloadStepDescriptionPresent: I18n.get('Download Device Firmware'),
  downloadStepDescriptionPast: I18n.get('Device Firmware Downloaded'),
  downloadFormPoeBtn: I18n.get('Enroll Ethernet Device'),
  downloadFormWifiBtn: I18n.get('Enroll Wifi Device'),
  pairStepTitlePresent: I18n.get('Pair'),
  pairStepTitlePast: I18n.get('Paired'),
  pairStepDescriptionPresent: I18n.get('Pair with Device'),
  pairStepDescriptionPast: I18n.get('Paired with Device'),
  pairDevicePrefixPlaceholder: I18n.get('Device Name Prefix'),
  pairFormConnectBtn: I18n.get('Connect'),
  connectStepTitlePresent: I18n.get('Connect'),
  connectStepTitlePast: I18n.get('Connected'),
  connectStepDescriptionPresent: I18n.get('Establish Secure Connection'),
  connectStepDescriptionPast: I18n.get('Established Secure Connection'),
  ssidPlaceholder: I18n.get('SSID'),
  passwordPlaceholder: I18n.get('Password'),
  configureStepTitlePresent: I18n.get('Configure'),
  configureStepTitlePast: I18n.get('Configured'),
  configureStepDescriptionPresent: I18n.get('Send Wifi Config'),
  configureStepDescriptionPast: I18n.get('Wifi Config Sent'),
  connectedStepTitlePresent: I18n.get('Connect Device'),
  connectedStepTitlePast: I18n.get('Device Connected'),
  connectedStepDescriptionPresent: I18n.get('Connect Device to Wifi'),
  connectedStepDescriptionPast: I18n.get('Device Connected to Wifi'),
  connectFormConfigureBtn: I18n.get('Configure'),
  locationFormTitle: I18n.get('Set Location'),
  locationFormLatitude: I18n.get('Latitude'),
  locationFormLongitude: I18n.get('Longitude'),
  locationStepTitlePresent: I18n.get('Set Location'),
  locationStepTitlePast: I18n.get('Location Set'),
  locationStepDescriptionPresent: I18n.get('Set Device Location'),
  locationStepDescriptionPast: I18n.get('Device Location Set'),
  locationFormAddBtn: I18n.get('Set Location'),
  tagStepTitlePresent: I18n.get('Set Tag'),
  tagStepTitlePast: I18n.get('Tag Set'),
  tagStepDescriptionPresent: I18n.get('Set Device Tag'),
  tagStepDescriptionPast: I18n.get('Device Tag Set'),
  tagFormTitle: I18n.get('Tag'),
  tagFormText: I18n.get('Max. 255 characters'),
  tagFormAddBtn: I18n.get('Set Tag'),
  notInUseText: I18n.get('Disable device'),
  completeFormTitle: I18n.get('Successfully enrolled device'),
  completeFormViewDeviceBtn: I18n.get('View Device'),
  completeFormEnrollAnotherDeviceBtn: I18n.get('Enroll Another Device'),
  workflowErrorTitle: I18n.get('An Error Occurred'),
  rootZonePlaceholder: I18n.get('All'),

  // Organizations
  orgsTab: I18n.get('Organizations'),
  orgNameColumn: I18n.get('Name'),
  orgIdColumn: I18n.get('ID'),
  noOrgsFound: I18n.get('No organizations found'),
  orgAddFormTitle: I18n.get('Create new organization'),
  orgAddButton: I18n.get('Add organization'),
  orgSave: I18n.get('Save organization'),
  orgCreated: I18n.get('New organization created.'),
  orgEditFormTitle: I18n.get('Edit organization'),
  orgUpdated: I18n.get('Organization updated.'),
  orgSearch: I18n.get('Search by organization name'),
  orgFieldName: I18n.get('Organization'),
  orgFieldPlaceholder: I18n.get('Select an organization'),
  roleOrgs: I18n.get('Role organizations'),
  allOrgs: I18n.get('All organizations'),
  customizationInfo: I18n.get('To enable further customization, please navigate to the edit view once it\'s created.'),

  // Audit logs
  logs: I18n.get('Logs'),
  logsViewSubtitle: I18n.get('View activity logs for'),
  logDate: I18n.get('Date'),
  logTime: I18n.get('Time'),
  logSuccessfulLogins: I18n.get('Successful logins'),
  logsLoading: I18n.get('Loading logs'),
  logUnsuccessfulLogins: I18n.get('Unsuccessful logins'),
  logActiveSessions: I18n.get('Active sessions'),
  logsNoEntries: I18n.get('No logs to display'),
  logsFetch: I18n.get('Fetch logs'),
  startDate: I18n.get('Start date'),
  endDate: I18n.get('End date'),

  //Device Management
  deviceManagementHeading: I18n.get('Device Management'),

  //Usage plan
  usagePlanHeader: I18n.get('Usage plan'),
  noUsagePlan: I18n.get('There is no usage plan for this organization.'),
  name: I18n.get('Name'),
  id: I18n.get('ID'),
  throttle: I18n.get('Throttle'),
  burstLimit: I18n.get('Burst limit'),
  rateLimit: I18n.get('Rate limit'),
  quota: I18n.get('Quota'),
  limit: I18n.get('Limit'),
  offset: I18n.get('Offset'),
  period: I18n.get('Period'),
  createUsagePlan: I18n.get('Add usage plan'),
  viewUsagePlan: I18n.get('View usage plan'),
  confirmAddUsagePlanHeader: I18n.get('Confirm new usage plan'),
  confirmAddUsagePlan: I18n.get('Are you sure you want to add a usage plan for this organization?'),
  confirmButton: I18n.get('Confirm'),
  saveChanges: I18n.get('Save changes'),
  editUsagePlan: I18n.get('Edit usage plan'),
  isRequired: I18n.get('is required'),
  mustBeNumber: I18n.get('must be a number'),
  noValuesHaveChanged: I18n.get('No values have been changed'),

  featureFlags: I18n.get('Feature Flags'),

  auditTab: I18n.get('Audit Logs'),
  suppliersTab: I18n.get('Suppliers'),

  goBack: I18n.get('Go back'),

  // FYI
  fyiPageHeader: I18n.get('FYI'),
  helpText: I18n.get('Help text'),
  helpTextSubheading: I18n.get('Manage help text shown in tooltips'),
  releaseNotes: I18n.get('Release notes'),
  tooltipIdColHeader: I18n.get('Tooltip ID'),
  defaultTextColHeader: I18n.get('Default text (English)'),
  addNewEntry: I18n.get('Add new entry'),
  addHelpText: I18n.get('Add Help Text'),
  editHelpText: I18n.get('Edit Help Text'),
  addHelpTextSubheading: I18n.get('Create a new help text entry.'),
  editHelpTextSubheading: I18n.get('Update existing help text'),
  helpTextEng: I18n.get('Help text'),
  releaseNotesEng: I18n.get('Release note text'),
  generateFromPrompt: I18n.get('Generate from prompt'),
  tags: I18n.get('Tags'),
  generateTranslations: I18n.get('Generate translations'),
  generateHelpTextHeading: I18n.get('Generate Help Text'),
  generateHelpTextSubheading: I18n.get('Use a prompt to generate suggested help text'),
  prompt: I18n.get('Prompt'),
  result: I18n.get('Result'),
  generateTextBtn: I18n.get('Generate text'),
  useTextBtn: I18n.get('Use suggested text'),
  es: I18n.get('Spanish'),
  ar: I18n.get('Arabic'),
  en: I18n.get('English'),
  selectTags: I18n.get('Select tags'),
  translations: I18n.get('Translations'),
  translationsFormSubheading: I18n.get('View and manage translations'),
  translationsToBeUpdated: I18n.get('The following translations will be updated:'),
  title: I18n.get('Title'),
  noTranslationAvailable: I18n.get('No translation available'),
  releaseNotesSections: I18n.get('Sections'),
  releaseNotesSection: I18n.get('Section'),
  manageReleaseNotesSections: I18n.get('Manage release notes sections'),
  addSection: I18n.get('Add section'),
  manageReleaseNote: I18n.get('Manage release notes'),
  createReleaseNoteItem: I18n.get('Create a new release note content item'),
  editReleaseNoteItem: I18n.get('Update the selected release note content item'),
  text: I18n.get('Text'),
  selectSectionPrompt: I18n.get('Select a section'),
  addReleaseNote: I18n.get('Add release note'),
  addReleaseNoteSubheading: I18n.get('Create a new release note'),
  add: I18n.get('Add'),
  content: I18n.get('Content'),
  noContentAdded: I18n.get('No content added yet'),
  manageSections: I18n.get('Manage sections'),
  addNewContentItem: I18n.get('Add content item'),
  editContentItem: I18n.get('Edit content item'),
  publishDate: I18n.get('Publish date'),
  published: I18n.get('Published'),
  visibleToUsers: I18n.get('Visible to users'),
  editReleaseNote: I18n.get('Edit release note'),
  editReleaseNoteSubheading: I18n.get('Update the release note details'),
  manageTags: I18n.get('Manage tags for help text and release notes'),
  addTag: I18n.get('Add tag'),
  addTagModalSubheading: I18n.get('Create a new FYI tag'),
  editTag: I18n.get('Edit tag'),
  editTagModalSubheading: I18n.get('Update the tag name'),

  // Organization Labels
  manageLabels: I18n.get('Manage labels'),
  settings: I18n.get('Settings'),

  active: I18n.get('Active'),
  inactive: I18n.get('Inactive')
})

export default Strings
