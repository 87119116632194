import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import { FlexV2, Symbol, Text } from '@/primitives'
import { Tooltip } from '@/elements'

import TypeIcon from './TypeIcon'
import Strings from '../../Strings'

import {
  getColorVariantFromViewData,
  caseInsensitiveIncludes
} from '@/components/StatusPage/utils'

import { getMeasurements } from '@/reducers/selectors'

/**
 * Renders a link in the tree view of the StatusPage component.
 *
 * @param {Object} props - The props to pass to the component.
 * @param {string} props.id - The ID of the device.
 * @param {string} props.name - The name of the device.
 * @param {string} props.sensorType - The type of the device.
 * @param {string} props.zonePath - The path to the zone of the device.
 * @param {Object} props.viewData - The view data of the device.
 * @returns {React.ReactElement} A React component that displays a link in the tree view of the StatusPage component.
 */
function TreeLink({ id, name, sensorType, zonePath, viewData }) {
  const strings = Strings()
  const tipRef = useRef(null)
  const [showTooltip, setShowTooltip] = useState(false)

  if (!id || !name || !sensorType || !zonePath) return null

  const measurements = getMeasurements()

  const { notInUse, missingData, reasons, status, sensorDashboardURL } =
    viewData

  const missingDataReasons = missingData ? reasons['missingData'] ?? [] : []

  const variant = getColorVariantFromViewData(viewData)

  function renderMeasurementName(id) {
    const measurement = measurements.find(measurement => measurement.id === id)
    return measurement?.shortName ?? id
  }

  function onMouseEnter() {
    setShowTooltip(true)
  }

  function onMouseLeave() {
    setShowTooltip(false)
  }

  return (
    <li
      className='StatusPage__Content__Overview__TreeLink'
      key={`${id}-${sensorType}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link to={sensorDashboardURL}>
        <FlexV2 alignCrossAxis='center' axisGap={300} className={'Description'}>
          <TypeIcon type={sensorType || 'generic'} {...viewData} />
          <Text variant='page' tone={900} className={'Name'}>
            {name}
          </Text>
          <Text variant='page' tone={700} size={100}>
            ({sensorType})
          </Text>
        </FlexV2>
        <FlexV2 className='Badges' axisGap={300}>
          {status !== 'active' ? (
            <Text
              className={'Badge Status'}
              variant={variant}
              tone={800}
              size={100}
              fontFamily='mono'
            >
              {strings[status]}
            </Text>
          ) : null}
          {notInUse ? (
            <Text
              className={'Badge NotInUse'}
              variant={'page'}
              tone={800}
              size={100}
              fontFamily='mono'
            >
              {strings.notInUse}
            </Text>
          ) : null}
          {missingData && missingDataReasons?.length > 0 ? (
            <Tooltip
              tip={missingDataReasons.map(renderMeasurementName).join(', ')}
              tipRef={tipRef}
              place='bottom'
              showTooltip={showTooltip}
            >
              <Text
                className={'Badge MissingData'}
                variant={'graph'}
                tone={800}
                size={100}
                fontFamily='mono'
              >
                {strings.missingData}
              </Text>
            </Tooltip>
          ) : null}
        </FlexV2>
      </Link>
    </li>
  )
}

/**
 * Renders a tree item in the tree view of the StatusPage component.
 *
 * @param {Object} props - The props to pass to the component.
 * @param {Object} props.data - The data of the tree item.
 * @param {string} props.searchValue - The search value to filter the tree items.
 * @param {boolean} props.expandAll - Whether all tree items should be expanded or not.
 * @returns {React.ReactElement} A React component that displays a tree item in the tree view of the StatusPage component.
 */
function TreeItem({ data, searchValue, expandAll }) {
  const { sensorType, name, devices, children, viewData } = data

  const { status } = viewData

  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  useEffect(() => {
    setOpen(expandAll)
  }, [expandAll])

  const memoizedDevices = useMemo(
    () =>
      devices
        .filter(device => caseInsensitiveIncludes(device.name, searchValue))
        .map(device => <TreeLink {...device} key={device.id} />),
    [devices, searchValue]
  )

  const memoizedChildren = useMemo(
    () =>
      Object.values(children).map(child => (
        <TreeItem
          data={child}
          searchValue={searchValue}
          expandAll={expandAll}
          key={child.id}
        />
      )),
    [children, searchValue, expandAll]
  )

  const memoizedHeader = useMemo(
    () => (
      <Fragment>
        <Symbol name={open ? 'keyboard_arrow_down' : 'chevron_right'} />
        <TypeIcon type={sensorType || 'folder'} open={open} status={status} />
        <Text size={200} fontWeight={700}>
          {name}
        </Text>
      </Fragment>
    ),
    [open, sensorType, name, status]
  )

  if (!data.name || !data.id || !data.viewData) {
    return null
  }

  return (
    <li
      className='StatusPage__Content__Overview__TreeItem'
      data-expanded={open}
    >
      <FlexV2
        as='button'
        alignCrossAxis='center'
        axisGap={300}
        onClick={handleClick}
      >
        {memoizedHeader}
      </FlexV2>
      <ul role='region'>
        {memoizedDevices}
        {memoizedChildren}
      </ul>
    </li>
  )
}

/**
 * Renders the tree of the StatusPage component.
 *
 * @param {Object} props - The props to pass to the component.
 * @param {Object} props.data - The data of the tree.
 * @param {string} props.searchValue - The search value to filter the tree.
 * @param {boolean} props.expandAll - Whether all tree items should be expanded or not.
 * @returns {React.ReactElement} A React component that displays the tree of the StatusPage component.
 */
export default function OverviewTree({ data, searchValue, expandAll }) {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 250)
  }, [])

  return (
    <ul className={'StatusPage__Content__Overview__Tree'} data-show={show}>
      <TreeItem data={data} searchValue={searchValue} expandAll={expandAll} />
    </ul>
  )
}
