import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useMobileDashboard } from '../../context'
import {
  getRootZone,
  getTimeframeGraphAverages,
  getTimeframeGraphAveragesIsLoading
} from '@/reducers/selectors'
import { fetchTimeframeGraphAverages } from '@/actions/ts/timeframe'
import Chart from './Chart/Chart'
import { Box, Loader, Text } from '@/primitives'
import Strings from '../../Strings'
import {
  getIntervalValueFromRange,
  getIntervalIdFromRange,
  getDatesFromRange,
  splitDataset
} from '../utils'
import { getChildIds } from '../../../utils'
import { convertToVPD } from '@/Util/MeasurementUtils'

const DataGraphs = ({ measurements, rangeId }) => {
  const { state } = useMobileDashboard()
  const dispatch = useDispatch()
  const rootZone = getRootZone()
  const mobileDataset = getTimeframeGraphAverages()
  const datasetIsLoading = getTimeframeGraphAveragesIsLoading()
  const strings = Strings()

  const { currentZone, siteId } = state
  const { organizationId, timeZone = 'UTC' } = rootZone

  const splitData = splitDataset(mobileDataset, measurements)
  const [zoneParamsList, setZoneParamsList] = useState([])

  useEffect(() => {
    if (measurements?.length > 0 && currentZone?.id) {
      const [fromDate, toDate] = getDatesFromRange(rangeId, timeZone)

      const childIds = getChildIds(currentZone?.children)

      const zoneIds = childIds.length > 0 ? childIds : [currentZone?.id]
      const zoneBaseData = {
        organizationId,
        siteId,
        sensorId: null,
        fromDate: fromDate.toUTC().toString(),
        toDate: toDate.toUTC().toString()
      }

      const zoneParamsList = zoneIds?.map(zoneId => ({
        ...zoneBaseData,
        zoneId
      }))

      setZoneParamsList(zoneParamsList)

      const params = {
        table: siteId,
        measurements,
        zoneParams: zoneParamsList,
        dateParams: {
          timeZone,
          dateRange: rangeId,
          timeInterval: getIntervalValueFromRange(rangeId),
          fromDate: fromDate.toUTC().toString(),
          toDate: toDate.toUTC().toString()
        },
        cacheOperation: true,
        isMobile: true,
        type: 'locationRanges',
        view: 'dashboard'
      }
      dispatch(fetchTimeframeGraphAverages(params))
    }
  }, [
    currentZone?.id,
    organizationId,
    currentZone?.children,
    rangeId,
    dispatch,
    siteId,
    measurements,
    timeZone
  ])

  const parseDataSetToVPD = () => {
    const temperatureData =
      splitData?.find(v => v[0]?.measurement === 'temperature') || []
    const humidityData =
      splitData?.find(v => v[0]?.measurement === 'humidity') || []

    const data = temperatureData.map((temperatureSplit, i) => {
      let data = []
      temperatureData[i].series[0].data.forEach((temperature, index) => {
        data.push({
          x: temperature.x,
          y: convertToVPD({
            temperature: temperature.y,
            humidity: humidityData[i].series[0].data[index].y
          })
        })
      })
      return {
        ...temperatureSplit,
        measurement: 'vapour_pressure_deficit',
        series: [{ ...temperatureData[i].series[0], data }]
      }
    })

    return data
  }

  return (
    <Fragment>
      <Loader isLoading={datasetIsLoading} text={strings.loadingData}>
        {mobileDataset && mobileDataset.length > 0 && (
          <Box style={{ width: '100%' }}>
            {measurements.map((measurement, index) => {
              return (
                <Chart
                  key={index}
                  measurementId={measurement}
                  data={
                    measurement === 'vapour_pressure_deficit'
                      ? parseDataSetToVPD()
                      : splitData[index]
                  }
                  interval={getIntervalIdFromRange(rangeId)}
                  zoneFilters={zoneParamsList}
                  timeZone={rootZone?.timeZone}
                />
              )
            })}
          </Box>
        )}
        {mobileDataset?.length === 0 && !datasetIsLoading && (
          <Box style={{ width: '100%', paddingTop: '1rem' }}>
            <Text as='p' tone={700} variant='page'>
              {strings.noData}
            </Text>
          </Box>
        )}
      </Loader>
    </Fragment>
  )
}

export default DataGraphs
