import { I18n } from 'aws-amplify'
import { MeasureByDepthWidget } from './MeasureByDepth'
import {
  CELLULAR_TUBULAR_SOILSENSE_MOISTURE,
  CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE
} from '@/components/DashboardPageV2/utils/TubularSoilsense'

export function tubularSoilSenseTypes() {
  return {
    [CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE]: {
      widgetId: 'cellularTubularSoilsenseTemperature',
      measurement: CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE,
      title: I18n.get('Temperature'),
      icon: 'thermostat',
      component: MeasureByDepthWidget
    },
    [CELLULAR_TUBULAR_SOILSENSE_MOISTURE]: {
      widgetId: 'cellularTubularSoilsenseMoisture',
      measurement: CELLULAR_TUBULAR_SOILSENSE_MOISTURE,
      title: I18n.get('Moisture'),
      icon: 'water damage',
      component: MeasureByDepthWidget
    }
  }
}
