import { I18n } from 'aws-amplify'

const strings = ({
    temperature: I18n.get('Temperature'),
    moisture: I18n.get('Moisture'),
    chartTitle: I18n.get('Long soil sensor'),
    aDepth: I18n.get('10cm'),
    bDepth: I18n.get('20cm'),
    cDepth: I18n.get('30cm'),
    dDepth: I18n.get('40cm'),
    eDepth: I18n.get('50cm'),
    fDepth: I18n.get('60cm'),
    gDepth: I18n.get('70cm'),
    hDepth: I18n.get('80cm'),
    iDepth: I18n.get('90cm')
})

export default strings