import React, { useEffect, useRef } from 'react'
import Highcharts from 'highcharts'

import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
import { strings } from './strings'

import './index.scss'
NoDataToDisplay(Highcharts)

const HighchartsComponent = ({ options }) => {
  const chartContainerRef = useRef(null)

  useEffect(() => {
    let chart

    if (chartContainerRef.current) {
      // Initialize Highcharts when the component is mounted
      chart = Highcharts.chart(chartContainerRef.current, {
        ...options,
        lang: strings,
        chart: {
          height: options.series.length ? '' : 100,
          ...options.chart
        },
        credits: {
          enabled: false
        }
      })
    }
    // Cleanup on component unmount
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [options]) // Re-run effect if options change

  return <div ref={chartContainerRef} />
}

export default HighchartsComponent
