export const CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE =
  'cellular_tubular_soilsense_temperature'
export const CELLULAR_TUBULAR_SOILSENSE_MOISTURE =
  'cellular_tubular_soilsense_moisture'

export const CELLULAR_TUBULAR_SOILSENSE_DEPTHS = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i'
]
export const CELLULAR_TUBULAR_SOILSENSE_TEMPERATURE_PREFIX = 's_t_'
export const CELLULAR_TUBULAR_SOILSENSE_MOISTURE_PREFIX = 's_m_'

export const CELLULAR_TUBULAR_SOILSENSE_DEPTH_COLORS = {
  a: '#9acd32',
  b: '#5dade2',
  c: '#f4c542',
  d: '#f28c33',
  e: '#ff69b4',
  f: '#e34234 ',
  g: '#8b4513',
  h: '#9370db',
  i: '#000000'
}
